import {
  ApplicationRoute,
  ChangeFreq,
  LOCALIZED_ROUTE_TOKEN,
  PublicStaticRoute,
} from '../../craft/utils/application.route'

export const PUBLIC_ROUTES = {
  HOME: new PublicStaticRoute('/', {changeFreq: ChangeFreq.MONTHLY, priority: 1}),
  HOME_LOCALIZED: new PublicStaticRoute(`/${LOCALIZED_ROUTE_TOKEN}`, {
    changeFreq: ChangeFreq.MONTHLY,
    priority: 1,
    routingPath: '',
  }),
  SEARCH: new PublicStaticRoute(`/${LOCALIZED_ROUTE_TOKEN}/search`),
  ABOUT_US: new PublicStaticRoute(`/${LOCALIZED_ROUTE_TOKEN}/about-us`),
  MEDIA: new PublicStaticRoute(`/${LOCALIZED_ROUTE_TOKEN}/media`),
  IMPRESSUM: new PublicStaticRoute(`/${LOCALIZED_ROUTE_TOKEN}/impressum`),
  CATEGORY_LANDING_PAGE_ROOT: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/page`),
  CATEGORY_LANDING_PAGE: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/page/:pageSlug`, ':pageSlug'),
  OFFERING_DETAIL_PAGE_ROOT: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/offering`),
  OFFERING_DETAIL_PAGE: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/offering/:pageSlug`, ':pageSlug'),
  PRO_DIRECTORY_PAGE: new PublicStaticRoute(`/${LOCALIZED_ROUTE_TOKEN}/services`, {changeFreq: ChangeFreq.WEEKLY}),
  OFFERING_PAGE_ROOT: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/services`),
  OFFERING_PAGE: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/services/:pageSlug`, ':pageSlug'),
  EMERGENCY_LANDING_PAGE_ROOT: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/emergency`),
  EMERGENCY_LANDING_PAGE: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/emergency/:pageSlug`, ':pageSlug'),
  ARTICLE_ROOT: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/article`),
  ARTICLE_OVERVIEW: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/article/overview`, 'overview'),
  TOPIC_OVERVIEW_PAGE: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/article/topic/:pageSlug`, 'topic/:pageSlug'),
  ARTICLE_PAGE: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/article/:pageSlug`, ':pageSlug'),
  ARTICLE_PAGE_UNAVAILABLE: new ApplicationRoute(
    `/${LOCALIZED_ROUTE_TOKEN}/article/unavailable/:pageSlug`,
    'unavailable/:pageSlug',
  ),
  INDUSTRY_OVERVIEW_PAGE_ROOT: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/industries`),
  INDUSTRY_OVERVIEW_PAGE: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/industries/:pageSlug`, ':pageSlug'),
  SERVICE_OVERVIEW_PAGE_ROOT: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/products`),
  SERVICE_OVERVIEW_PAGE: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/products/:pageSlug`, ':pageSlug'),
  CALCULATOR_PAGE: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/calculators/:pageSlug`, 'calculators'),
  MOBILIAR_HOME_PAGE_DE: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/diemobiliar`),
  MOBILIAR_HOME_PAGE_EN: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/lamobiliere`),
  MOBILIAR_HOME_PAGE_FR: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/lamobiliere`),
  MOBILIAR_HOME_PAGE_IT: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/lamobiliare`),
  LEGAL: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/legal`),
  PRO_TERMS_AND_CONDITIONS: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/legal/pro-terms-and-conditions`),
  CLIENT_TERMS_AND_CONDITIONS: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/legal/client-terms-and-conditions`),
  DATA_PRIVACY: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/legal/data-privacy`),
  ERNEUREBARHEIZEN: new ApplicationRoute(
    `/${LOCALIZED_ROUTE_TOKEN}/partner/erneuerbarheizen`,
    'partner/erneuerbarheizen',
  ),
  ERNEUREBARHEIZEN_DETAIL: new PublicStaticRoute(`/${LOCALIZED_ROUTE_TOKEN}/partner/erneuerbarheizen/details`, {
    routingPath: 'details',
  }),
  ERNEUREBARHEIZEN_SUSTAINABLE_HEATING: new PublicStaticRoute(
    `/${LOCALIZED_ROUTE_TOKEN}/partner/erneuerbarheizen/sustainable-heating`,
    {routingPath: 'sustainable-heating'},
  ),
  UNSUPPORTED_LANGUAGE_PAGE: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/language-not-supported`),
  ERROR: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/error`),
  UNKNOWN_ERR: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/error/unknown`, 'unknown'),
  NOT_FOUND: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/error/not-found`, 'not-found'),
  AUTH_ERR: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/error/auth`, 'auth'),
  SIGN_IN: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/sign-in`),
  SIGN_IN_RETURNING: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/sign-in/returning`, 'returning'),
  PRO_REGISTER: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/register/pro`, 'register/pro'),
  PRO_REGISTER_PLAN: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/register/pro/plan`),
  PRO_REGISTER_WELCOME: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/register/pro/welcome`),
  PRO_REGISTER_DETAIL: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/register/pro/:invitationCode/detail`),
  PRO_REGISTER_SKILLS: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/register/pro/:invitationCode/skills`),
  PRO_REGISTER_SUCCESS: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/register/pro/:invitationCode/success`),
  PRO_PROFILE_ROOT: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/profile`),
  PRO_PROFILE: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/profile/:slug`, ':slug'),
  JOB_REQUEST: new ApplicationRoute(
    `/${LOCALIZED_ROUTE_TOKEN}/offerings/:offeringId/create-request`,
    'offerings/:offeringId/create-request',
  ),
  JOB_REQUEST_DETAIL: new ApplicationRoute(
    `/${LOCALIZED_ROUTE_TOKEN}/offerings/:offeringId/create-request/detail`,
    'detail',
  ),
  JOB_REQUEST_CONTACT: new ApplicationRoute(
    `/${LOCALIZED_ROUTE_TOKEN}/offerings/:offeringId/create-request/contact`,
    'contact',
  ),
  JOB_REQUEST_EXECUTION_TIME: new ApplicationRoute(
    `/${LOCALIZED_ROUTE_TOKEN}/offerings/:offeringId/create-request/time`,
    'time',
  ),
  JOB_REQUEST_SUMMARY: new ApplicationRoute(
    `/${LOCALIZED_ROUTE_TOKEN}/offerings/:offeringId/create-request/summary`,
    'summary',
  ),
  JOB_REQUEST_SUCCESS: new ApplicationRoute(
    `/${LOCALIZED_ROUTE_TOKEN}/offerings/:offeringId/create-request/success/:id`,
    'success/:id',
  ),
  JOB_REQUEST_INTENTION: new ApplicationRoute(
    `/${LOCALIZED_ROUTE_TOKEN}/offerings/:offeringId/create-request/intention`,
    'intention',
  ),
  JOB_REQUEST_CLIENT_EXPECTATION: new ApplicationRoute(
    `/${LOCALIZED_ROUTE_TOKEN}/offerings/:offeringId/create-request/expectation`,
    'expectation',
  ),
  AGENCY_PUBLIC_SPACE: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/agency/:agencySlug`, 'agency/:agencySlug'),
  CRAFTSMEN_REGION_PAGE_ROOT: new PublicStaticRoute(`/${LOCALIZED_ROUTE_TOKEN}/craftsmen`),
  CRAFTSMEN_REGION_PAGE: new ApplicationRoute(`/${LOCALIZED_ROUTE_TOKEN}/craftsmen/:regionSlug`, ':regionSlug'),
  CRAFTSMEN_REGION_INDUSTRY_PAGE: new ApplicationRoute(
    `/${LOCALIZED_ROUTE_TOKEN}/craftsmen/:regionSlug/:industrySlug`,
    ':industrySlug',
  ),
}
