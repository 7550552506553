export const LOCALIZED_ROUTE_TOKEN = ':locale'

export enum ChangeFreq {
  DAILY = 'daily',
  MONTHLY = 'monthly',
  ALWAYS = 'always',
  HOURLY = 'hourly',
  WEEKLY = 'weekly',
  YEARLY = 'yearly',
  NEVER = 'never',
}

export class ApplicationRoute {
  /** full path of the route */
  readonly path: string

  /**
   * if specified in the constructor, value provided will be used
   * by default, last path segment of the route
   * home => home
   * path/to/here => here
   */
  readonly routingPath: string

  /** whether the route contains the language in its path */
  readonly isLocalized: boolean

  /**
   * path relative to the same level
   * /path/to/child => ../child
   */
  readonly sameLevelPath: string

  /**
   * is the route included in the sitemap?
   */
  indexPage: boolean

  constructor(path: string, routingPath?: string) {
    const splitPath = path.split('/')
    const lastPathSegment = splitPath[splitPath.length - 1]

    this.path = path
    this.routingPath = routingPath ?? lastPathSegment
    this.isLocalized = path.includes(LOCALIZED_ROUTE_TOKEN)
    this.sameLevelPath = `../${this.routingPath}`
    this.indexPage = false
  }

  get pathWithoutLocalizedRouteToken(): string {
    return this.path.replace(`/${LOCALIZED_ROUTE_TOKEN}`, '')
  }
}

interface PublicStateRouteArgs {
  routingPath?: string
  priority?: number
  changeFreq?: ChangeFreq
}

export class PublicStaticRoute extends ApplicationRoute {
  /**
   * Sitemap priority between 0-1
   */
  readonly priority?: number

  readonly changeFreq?: ChangeFreq

  constructor(path: string, args: PublicStateRouteArgs = {}) {
    super(path, args.routingPath)
    this.indexPage = true
    this.priority = args.priority
    this.changeFreq = args.changeFreq
  }
}
