import {Injectable} from '@angular/core'
import {ApplicationRoute, LOCALIZED_ROUTE_TOKEN} from './application.route'
import {I18nService} from '../i18n/i18n.service'
import {getPathFromUrl} from '../../core/utils/url'

@Injectable({providedIn: 'root'})
export class PathService {
  constructor(private readonly i18n: I18nService) {}

  localizedPathTo(destination: ApplicationRoute, opts?: PathOptions, language?: string): string {
    const lang = (() => {
      if (language) return language
      else return this.i18n.getActiveLang()
    })()

    const stripFragments = opts?.stripFragments === undefined ? true : opts.stripFragments
    // replace the locale route param and get the path without a potential fragment & query params
    let path = destination.path.slice().replace(LOCALIZED_ROUTE_TOKEN, lang)
    if (stripFragments) {
      path = getPathFromUrl(path)
    }

    // replace potential route params
    if (opts?.routeParams) {
      for (const [key, value] of Object.entries(opts.routeParams)) {
        path = path.replace(`:${key}`, value)
      }
    }

    // add potential query params
    if (opts?.queryParams) {
      const queryParams = Object.entries(opts.queryParams)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join('&')
      path = `${path}?${queryParams}`
    }

    return path
  }
}

export interface PathOptions {
  queryParams?: Record<string, string>
  routeParams?: Record<string, string>
  /**
   * When true, the fragment will be stripped from the path
   * @default true in order to be compatible with angular routerLink, which expects fragments as a separate input
   */
  stripFragments?: boolean
}
